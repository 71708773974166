.form-floating {
    label {
        color: #8B929A !important;
    }
}

.form-control {
    --bs-border-color: #DDE0E4;
}

input[type="radio"].form-check-input:checked {
    background-color: transparent;
    border-color: #16B054;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%2316B054%27/%3e%3c/svg%3e")!important;
    &:focus {
        box-shadow: none;
    }
}

input[type="checkbox"].form-check-input:checked {
    background-color: transparent;
    border-color: #16B054;
    border: none;
    --bs-form-check-bg-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuMjUgMEMxLjI4MzUgMCAwLjUgMC43ODM1MDIgMC41IDEuNzVWMTIuMjVDMC41IDEzLjIxNjUgMS4yODM1IDE0IDIuMjUgMTRIMTIuNzVDMTMuNzE2NSAxNCAxNC41IDEzLjIxNjUgMTQuNSAxMi4yNVYxLjc1QzE0LjUgMC43ODM1MDIgMTMuNzE2NSAwIDEyLjc1IDBIMi4yNVpNMTEuMDI2NSA0LjM0ODQ2QzExLjI3OTcgNC42MDE2IDExLjI4MjggNS4wMTAwOSAxMS4wMzU4IDUuMjY3MDRMNy41NDI2NyA5LjYzMzUxQzcuNTM3NjMgOS42Mzk4MSA3LjUzMjI0IDkuNjQ1ODMgNy41MjY1MyA5LjY1MTU0QzcuMjcwMjUgOS45MDc4MiA2Ljg1NDc0IDkuOTA3ODIgNi41OTg0NiA5LjY1MTU0TDQuMjgyODEgNy4zMzU5QzQuMDI2NTMgNy4wNzk2MiA0LjAyNjUzIDYuNjY0MSA0LjI4MjgxIDYuNDA3ODJDNC41MzkxIDYuMTUxNTQgNC45NTQ2MSA2LjE1MTU0IDUuMjEwODkgNi40MDc4Mkw3LjA0MjcyIDguMjM5NjVMMTAuMDgxIDQuMzY4MDlDMTAuMDg2NSA0LjM2MTIxIDEwLjA5MjMgNC4zNTQ2NSAxMC4wOTg1IDQuMzQ4NDZDMTAuMzU0NyA0LjA5MjE4IDEwLjc3MDMgNC4wOTIxOCAxMS4wMjY1IDQuMzQ4NDZaIiBmaWxsPSIjMTZCMDU0Ii8+Cjwvc3ZnPgo=")!important;
    &:focus {
        box-shadow: none;
    }
}

.nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: #7b8495;
    --bs-nav-link-hover-color: #16b054;
    --bs-nav-link-disabled-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.nav-link {
    color: var(--bs-nav-link-color);
    display: block;
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:focus,.nav-link:hover {
    color: var(--bs-nav-link-hover-color)
}

.avatar {
    align-items: center;
    background-color: #e5faed;
    color: #139046;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px
}

.btn-success {
    --bs-btn-disabled-bg: #DDE0E4;
    --bs-btn-disabled-border-color: #DDE0E4;
}

.dashed-button {
    border: 1px dashed #16b054;
}

.cursor-pointer {
    cursor: pointer;
}

.hide-arrows {
    input[type=number] {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      
        & {
          -moz-appearance: textfield;
        }
    }
}

@media screen and (min-width: 1400px) {
    .width-wrapper {
        width: 930px;
    }
}

.field-ticket {
    @media screen and (min-width: 992px) {
        width: 18%!important    ;
    }
}
.field-ticket-date {
    @media screen and (min-width: 992px) {
        width: 28%!important    ;
    }
}

.btn-stripe {
    --bs-btn-disabled-bg: #DDE0E4;
    --bs-btn-disabled-border-color: #DDE0E4;
}

.table {
    --bs-table-bg: transparent!important;
    color: #3E4957!important;
}

.wrapper {
    @media (min-width: 992px) {
          min-height: 900px;
      }
}
