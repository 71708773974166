$success:#16B054;
$success-light: #F9FEFB;
$light-gray:#FBFBFB;
$primary:#164AB0;
$info:#8B929A;
$secondary: #DDE0E4;
$danger:#F44336;
$dark:#3E4957;
$background-gray:#F5F5F5;
$min-contrast-ratio: 2;
$border-radius: .2rem;
$theme-colors: (
        "success": $success,
        "primary": $primary,
        "info":#8B929A,
        "secondary": $secondary,
        "danger": $danger,
        "stripe": #635BFF,
        "dark": #3E4957,
        "input-gray": #757775,
        "success-light":$success-light,
        "light-gray":$light-gray,
        "background-gray":$background-gray,
        "success-bg": #E5FAED,
        "warning": #E2B02F,
        "warning-light": #FFFAED,
        "footer-color": #4A5360,
        "danger-bg":#FEDEDC,
        "blue":#6F93EF,
        "blue-bg":#D8E6FD,
        "org-gray":#6D819B,
        "qr-light":#FDF3FF,
        "qr-primary":#9B27AF,
        "sec-background":#D0DAEE,
        "sec-background-light":#F3F6FD,
        "light-blue":#9FB3D9

);
$btn-font-weight: 700;
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.125rem;
$h6-font-size: 1rem;
$h7-font-size: 0.875rem;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1140px
);
$font-family-sans-serif: 'Arial';
$headings-font-weight: 700;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1450px
);

$font-sizes: (
  1: 2.5rem,
  2: 2rem,
  3: 1.75rem,
  4: 1.5rem,
  5: 1.25rem,
  6: 1rem,
  7: 0.875rem,
  8: 1.125rem
);

$enable-validation-icons: false;

@import '~bootstrap/scss/bootstrap';
@import './App.scss';